<template>
  <RmaContainer ref="rmaContainer">
    <SelfservicePageLayout>
      <template #default>
        <p>Kære kunde!</p>
        <p>
          Pr. 1. januar 2018 træder nedenstående retningslinjer i kraft, således vi kan yde dig den
          bedste service ved reklamationsbehandling.
        </p>
        <p>
          <strong>Varen sendes til AD Danmark</strong><br />
          Varen returneres til den AD Danmark afdeling, hvor varen er købt.
        </p>
        <p>Sammen med varen skal der være følgende:</p>
        <ol>
          <li>Udfyldt reklamationsblanket inkl. evt. fejlkode</li>
          <li>Fakturakopi af købet ved AD Danmark</li>
          <li>Faktura på arbejdstimerne</li>
        </ol>

        <p>
          Det er i orden at sende papirerne pr. mail, men der skal altid medfølge kopier med
          reservedelen. Ved kostbare reklamationer, evt. med følgeskader (tandrem o.l.), konsulteres
          Teknologisk Institut, hvis der er den mindste tvivl om, hvorvidt det er en reklamation
          eller ej. Ved en uberettiget reklamation viderefakturerer AD Danmark omkostningen for
          undersøgelsen (for 2018 er prisen 5.057,- ekskl. moms og kørsel).
        </p>
        <p>
          <strong>Hvad sker der ved godkendt reklamation?</strong><br />
          I tilfælde af at AD Danmark har godkendt reklamationen, har værkstedet ret til:
        </p>
        <ol>
          <li>omkostningsfri reparation eller ombytning af produkt og</li>
          <li>eventuelt erstatning for arbejdsløn</li>
        </ol>
        <p>jf. punktet om erstatning for arbejdsomkostninger.</p>
        <p>
          I øvrigt kan værkstedet ikke gøre krav på andre faktorer (fejlsøgning, lånebil, transport,
          test m.m.), som følge af købet af dette produkt.
        </p>
      </template>
    </SelfservicePageLayout>
  </RmaContainer>
</template>

<script>
import SelfservicePageLayout from '@scenes/SelfserviceScene/layouts/Page';
import { Button, sizes } from '@components/Buttons';
import RmaContainer from '@scenes/SelfserviceScene/containers/RmaContainer';

import { RmaFilterTypes } from '@types/Rma';

export default {
  name: 'RmaDefaultOverview',
  components: {
    SelfservicePageLayout,
    Button,
    RmaContainer,
  },

  created() {
    this.rmaFilterTypes = RmaFilterTypes;
  },

  methods: {
    createNewRma() {
      this.$refs.rmaContainer.createRma({ type: RmaFilterTypes.COMPLAINT }).then(rmaId => {
        this.$router.push({ name: '/selfservice/returns/:id', params: { id: rmaId } });
      });
    },
  },
};
</script>
